import React, { useState } from  'react';
import { FaPlus, FaMinus } from 'react-icons/fa'
import { main, faqItem, question, answer } from './Faq.module.css';
import { useTranslation } from "react-i18next";

export default () => {
    const [state, setState] = useState({
        activeItemIndex: 0
    });

    const { t } = useTranslation();

    const toggleAccordionItem = (activeItemIndex) => {
        setState(prevState => {
            if (prevState.activeItemIndex === activeItemIndex) {
                return { ...prevState, activeItemIndex: null };
            } else {
                return { ...prevState, activeItemIndex };
            }
        });
    };

    const copy = t('home.faq', { returnObjects: true });

    return (
        <ul className={main}>
            {copy.items.map((item, index) => (
                <li key={index} className={faqItem}>
                    <div
                        tabIndex="0"
                        role="button"
                        className={question}
                        onClick={() => toggleAccordionItem(index)}
                        onKeyPress={() => toggleAccordionItem(index)}
                    >
                        <strong>{`${index + 1}. ${item.question}`}</strong>
                        <span>
                            {state.activeItemIndex === index ? <FaMinus color="black" /> : <FaPlus color="silver" />}
                        </span>
                    </div>
                    <div
                        className={answer}
                        style={{ display: state.activeItemIndex === index ? '' : 'none'}}
                    >
                        {item.answer.paragraphs.map((p, index) => (
                            <p key={index}>{p}</p>
                        ))}
                    </div>
                </li>
            ))}
        </ul>
    );
}
