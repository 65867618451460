import React from 'react';
import { Link } from 'gatsby';
import { FaBolt } from 'react-icons/fa'
import { main, mobileImage, desktopImage } from './Service.module.css';
import Button from './Button';

export default ({ data, ...rest }) => (
    <div className={main} {...rest}>
        <div>
            <span><data.icon size='2.5em' /></span>
            <h1>{data.title}</h1>
            <div className={mobileImage}>
                {data.imageData && <img alt={data.title} src={data.imageData.resize.src} />}
            </div>
            {data.content.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
            <ul>
                {data.bulletPoints.map((point, index) => (
                    <li key={index}>
                        <FaBolt size="1em" color="var(--lightYellow)"/>
                        <span>{point}</span>
                    </li>
                ))}
            </ul>
            {data.callToAction && (
                <Link to={data.callToAction.url}>
                    <Button>{data.callToAction.label}</Button>
                </Link>
            )}
        </div>
        <div className={desktopImage}>
            {data.imageData && <img alt={data.title} src={data.imageData.resize.src} />}
        </div>
    </div>
);
