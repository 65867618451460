import React from "react";
import { customerReviews, quotation, instructions, faq } from './InternalBottom.module.css';
import { useTranslation } from "react-i18next";
import InternalSectionTitle from './InternalSectionTitle';
import CustomerReviews from './CustomerReviews';
import GetQuoteForm from './GetQuoteForm';
import Faq from './Faq';
import useMediaQuery from '../hooks/useMediaQuery';

export default ({ service = false, content }) => {
    const { t } = useTranslation();

    const isDesktop = useMediaQuery('(min-width: 769px)');

    return (
        <>
            {service && (
                <>
                    <div className={instructions}>
                        <h2>{content.howDoesItWork.title}</h2>
                        {content.howDoesItWork.paragraphs.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                    <div className={instructions}>
                        <h2>{content.whatDoWeDo.title}</h2>
                        {content.whatDoWeDo.paragraphs.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                </>
            )}
            <div className={customerReviews}>
                <InternalSectionTitle
                    style={{ marginBottom: '1.5em' }}
                >
                    {t('common.internalBottom.customerReviews.title')}
                </InternalSectionTitle>
                <CustomerReviews />
            </div>
            <div className={quotation}>
                <h3>{t('common.internalBottom.quotation.title')}</h3>
                <GetQuoteForm flavor='solid' horizontal={isDesktop || null}/>
            </div>
            <div className={faq}>
                <h4>{t('common.internalBottom.faq.title')}</h4>
                <h3>{t('common.internalBottom.faq.subtitle')}</h3>
                <br />
                <br />
                <Faq />
            </div>
        </>
    );
}
