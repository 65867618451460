import React, { useEffect, useState } from 'react';
import { FaStar, FaCircle } from 'react-icons/fa';
import {
    main, reviews, review, rating, customerProfile, reviewsNavigation
} from './CustomerReviews.module.css';
import useCustomerReviews from '../hooks/useCustomerReviews';
import useMediaQuery from '../hooks/useMediaQuery';

export default () => {
    const [state, setState] = useState({
        step: 0,
    });

    const customerReviews = useCustomerReviews();

    const mql = useMediaQuery('(min-width: 769px)');

    const maxSteps = mql ? 3 : customerReviews.length;

    const width = (100*customerReviews.length)/(mql ? 3 : customerReviews.length / customerReviews.length);

    const setStep = (step) => {
        setState(prevState => ({ ...prevState, step }));
    };

    useEffect(() => {
        const getNextStep = (prevStep) => {
            if (prevStep < maxSteps - 1) {
                return prevStep + 1;
            } else {
                return 0;
            }
        }

        const interval = setInterval(() => {
            setState(prevState => ({ ...prevState, step: getNextStep(prevState.step) }));
        }, 5000);

        return () => clearInterval(interval);
    }, [state.step, maxSteps])


    return (
        <div className={main}>
            <div
                className={reviews}
                style={{
                    width: `${width}%`,
                    transform: `translateX(-${(100 / customerReviews.length) * state.step}%)`
                }}
            >
                {customerReviews.map((item, i) => (
                    <div key={i} className={review}>
                        <div>
                            <span>‟</span>
                        </div>
                        <div>
                            <span>{item.text}</span>
                            <div className={rating}>
                                {Array.from({ length: item.rating }).map((_, ii) => (
                                    <FaStar key={ii} color="#FAA61A" />
                                ))}
                            </div>
                            <div className={customerProfile}>
                                <img alt={`${item.author_name} profile`}src={item.profile_photo_url} />
                                <span>{item.author_name}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={reviewsNavigation}>
                {Array.from({ length: maxSteps }).map((_, index) => (
                    <span
                        key={index}
                        tabIndex="0"
                        role="button"
                        onKeyPress={() => setStep(index)}
                        onClick={() => setStep(index)}
                    >
                        <FaCircle color={state.step === index ? 'var(--red)' : 'unset'}/>
                    </span>
                ))}
            </div>
        </div>
    );
}
